import { User } from 'oidc-client';

declare module 'oidc-client' {
    interface User {
        id: string;
        username: string;
    }
}

Object.defineProperty(User.prototype, 'id', {
    get() {
        // return "hello";
        return this.profile['sub'];
    }
});

Object.defineProperty(User.prototype, 'username', {
    get() {
        // return "username";
        return this.profile['name'];
    }
});

Object.defineProperty(User.prototype, 'organisationId', {
    get() {
        return this.profile['OrganisationIdentifier'];
    }
});

Object.defineProperty(User.prototype, 'organisation', {
    get() {
        return this.profile['Organisation'];
    }
});
