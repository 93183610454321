import { Logger, User, UserManager, UserManagerSettings } from 'oidc-client';
import '@/oidc-client-extensions';
import { ClientAuthenticationApiClient, ClientAuthorisationApiClient } from '@/repositories';
import { accessDeniedPage, errorPage, loadingPage } from '@/pages';
import authenticatedFetch from '@/authenticatedFetch';

// Uncomment to see oidc-client log messages
// Log.logger = console;
// Log.level = Log.DEBUG;

export class GlobalRoamAuthentication {
    static completeLoginFlag = 'completeLogin';
    static completeSilentLoginFlag = 'completeSilentLogin';

    static async authenticate(policies?: string[], logOutput?: Logger) {
        return new Promise<GlobalRoamAuthentication>(async (resolve, reject) => {
            const loading = loadingPage();

            const authentication = new GlobalRoamAuthentication(logOutput);
            try {
                if (await authentication.login()) {
                    window.fetch = authenticatedFetch(authentication);

                    // check policies
                    const authorisationClient = new ClientAuthorisationApiClient();
                    const isAuthorisedDto = await authorisationClient.isAuthorised(policies);

                    if (isAuthorisedDto.isAuthorised) {
                        resolve(authentication);
                    } else {
                        accessDeniedPage(authentication);
                    }
                }
            } catch (e) {
                errorPage(e as string);
                reject(e);
            } finally {
                loading.$destroy();
            }
        });
    }

    private _id = Math.floor(Math.random() * 100) + 1;
    private _user!: User;
    private _userManager: UserManager | undefined = undefined;
    private _logOutput?: Logger;

    private constructor(logOutput?: Logger) {
        this._logOutput = logOutput;
        this.info(`***** authentication  *****`);
    }

    private get userManager() {
        return this._userManager!;// as UserManager;
    }

    get user() {
        return this._user;
    }

    async login() {
        this._userManager = await this.initialiseUserManager();

        // complete callbacks if we have returned for that purpose
        if (await this.tryCompleteSilentLogin()) return false;
        if (await this.tryCompleteNormalLogin()) return false;

        // try and login
        if (await this.tryLoadUser()) return true;
        if (await this.trySilentLogin()) return true;

        return await this.normalLogin();
    }

    async logout() {
        this.info(`logout`);
        await this.userManager.signoutRedirect();
    }

    private changeEmailUrl() {
        const authHost = this.userManager.settings.authority;
        this.info(`changeEmailUrl : ${authHost}/change-email`);
        return `${authHost}/change-email`;
    }

    private async tryCompleteSilentLogin() {
        if (location.search.includes(GlobalRoamAuthentication.completeSilentLoginFlag)) {
            this.info('tryCompleteSilentLogin : completing silent login');
            await this.userManager.signinSilentCallback();
            this.info(`tryCompleteSilentLogin : completed`);
            return true;
        }
        return false;
    }

    private async tryCompleteNormalLogin() {
        if (location.search.includes(GlobalRoamAuthentication.completeLoginFlag)) {
            this.info(`tryCompleteNormalLogin : completing normal login`);
            const user = await this.userManager.signinRedirectCallback();
            this.info(`tryCompleteNormalLogin : completed : navigating to ${user.state.returnUrl}`);
            window.location.replace(user.state.returnUrl);
            return true;
        }
        return false;
    }

    private async initialiseUserManager(): Promise<UserManager> {
        const apiClient = new ClientAuthenticationApiClient();
        const clientDto = await apiClient.getJavaScriptClient();
        const userManagerSettings = {
            authority: clientDto.authority,
            client_id: clientDto.clientId,
            client_secret: clientDto.clientId,
            redirect_uri: clientDto.redirectUrl,
            response_type: clientDto.responseType,
            scope: clientDto.scope,
            post_logout_redirect_uri: clientDto.postLogoutRedirectUrl,
            silent_redirect_uri: clientDto.silentRedirectUrl,
            automaticSilentRenew: true
            // silentRequestTimeout: 2000
        } as UserManagerSettings;

        const userManager = new UserManager(userManagerSettings);

        userManager.events.addAccessTokenExpired((...ev: any[]) => {
            this.warn(`event : accessTokenExpired : ${JSON.stringify(ev)}`);
            this.info(`event : accessTokenExpired : attempting manual silent signin`);
            this.userManager.signinSilent();
        });
        userManager.events.addAccessTokenExpiring((...ev: any[]) => {
            this.info(`event : accessTokenExpiring : ${JSON.stringify(ev)} : expires in ${this.user.expires_in} seconds`);
        });
        userManager.events.addSilentRenewError((error: Error) => this.warn(`event : silentRenewError : ${JSON.stringify(error)}`));
        userManager.events.addUserSessionChanged(async () => {
            this.info(`event : userSessionChanged - force a silent signin to refresh user`);
            await this.userManager.removeUser();
            await this._userManager?.signinSilent();
            location.reload();
        });
        userManager.events.addUserUnloaded(() => this.info(`event : userUnloaded`));

        // handle sign out from another application
        userManager.events.addUserSignedOut(async () => {
            this.info(`event : userSignedOut`);
            await userManager.removeUser();
            location.reload();
        });
        userManager.events.addUserLoaded(user => {
            this.info(`event : userLoaded : access_token ending with ${user.access_token.substr(user.access_token.length - 10)} expires in ${user.expires_in} seconds (userId ${user.profile['sub']} )`);
            this.info(`event : userLoaded :  user name == ${user.profile['name']}`);
            this._user = user;
        });

        await userManager.clearStaleState();
        return userManager;
    }

    private async tryLoadUser() {
        const user = await this.userManager.getUser();
        if (user && !user.expired) {
            this.info(`loadUser : valid user found, you are logged in (userId ${user.profile['sub']})`);
            this.info(`access_token ending with ${user.access_token.substr(user.access_token.length - 10)} expires in ${user.expires_in} seconds`);
            this.info(`loadUser : user name == ${user.profile['name']}`);
            this._user = user;
            return true;
        }
        return false;
    }
    
    private async trySilentLogin() {
        try {
            this.info(`silentLogin : starting`);
            var silentUser = await this.userManager.signinSilent();
            this.info(`silentLogin : silentUser loaded : ${silentUser.profile['sub']}`);
            this.info(`silentLogin : completed`);
            return true;
        } catch (err) {
            this.info(`silentLogin : failed : ${err}`);
            return false;
        }
    }

    private async normalLogin() {
        this.info(`normalLogin : starting`);
        await this.userManager.signinRedirect({
            state: { returnUrl: window.location.href }
        });
        this.info(`normalLogin : completed : navigating away`);
        return false;
    }

    private info(message?: any, ...args: any[]) {
        if (this._logOutput)
            this._logOutput.info(`${new Date(Date.now()).toLocaleString()} : ${window.location.origin}${window.location.pathname}${window.location.search} (${this._id}) : ${message}`, ...args);
    }

    private warn(message?: any, ...args: any[]) {
        if (this._logOutput)
            this._logOutput.warn(`${new Date(Date.now()).toLocaleString()} : ${window.location.origin}${window.location.pathname}${window.location.search} (${this._id}) : ${message}`, ...args);
    }
}

export default GlobalRoamAuthentication;
