import GlobalRoamAuthentication from "@/authentication";
import { errorPage } from "@/pages";

declare type fetchFunction = (
  input: RequestInfo,
  init?: RequestInit
) => Promise<Response>;

function fetchWithErrorPage(fetch: fetchFunction) {
  return {
    fetch: (url: RequestInfo, init?: RequestInit) => {
      return new Promise<Response>((resolve, reject) => {
        fetch(url, init)
          .then(response => {
            if (response.status < 500) {
              resolve(response);
            } else {
              response.text().then(text => {
                errorPage(text);
                resolve(response.clone());
              });
            }
          })
          .catch(error => reject(error));
      });
    }
  };
}

function authenticatedFetch(authentication: GlobalRoamAuthentication) {
  const windowFetch = window.fetch;

  return (input: RequestInfo, init?: RequestInit) => {
    const url = typeof input === "string" ? input : input.url;
    let globalRoamDomains = [
      // Production
      /global-roam.com/,
      /nemreview.info/,
      /ez2view.info/,
      /deside.info/,
      /wattclarity.com.au/,
      /datastripe1.com/,

      // CI
      /global-roam-ci.com/,

      // Development
      /localhost/
    ];

    const headers = new Headers(init?.headers);
    if (url.startsWith("/") || globalRoamDomains.some(x => url.match(x))) {
      headers.append(
        `Authorization`,
        `Bearer ${authentication.user.access_token}`
      );
    }
    return fetchWithErrorPage(windowFetch).fetch(input, { ...init, headers });
  };
}

export { authenticatedFetch };

export default authenticatedFetch;
