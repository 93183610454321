import { PluginObject } from 'vue';
import { VueConstructor } from 'vue/types/vue';
import GlobalRoamAuthentication from './authentication';
import { GrAuthenticationProfile } from '@/components';
import { Logger } from 'oidc-client';

declare module 'vue/types/vue' {
    interface Vue {
        $authentication: GlobalRoamAuthentication;
    }
}

export async function GlobalRoamVueAuthentication(options?: { policies?: string[], logOutput?: Logger }): Promise<PluginObject<any>> {
    const authentication = await GlobalRoamAuthentication.authenticate(options?.policies, options?.logOutput);
    return new GlobalRoamVueAuthenticationPlugin(authentication);
}

export class GlobalRoamVueAuthenticationPlugin {
    constructor(private _authentication: GlobalRoamAuthentication) {}

    install(vue: VueConstructor) {
        const { _authentication: authentication } = this;

        Object.defineProperty(vue.prototype, '$authentication', {
            get() {
                return authentication;
            }
        });

        vue.component('gr-authentication-profile', GrAuthenticationProfile);
    }
}
