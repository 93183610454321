import Vue from 'vue';
import AccessDeniedPage from './access-denied.vue';
import GlobalRoamAuthentication from '../../authentication';
import Vuetify from 'vuetify/lib';

export const accessDeniedPage = (authentication: GlobalRoamAuthentication) => {
    Vue.use(Vuetify);
    const vuetify = new Vuetify({ theme: { dark: true } });
    new Vue({
        vuetify,
        render: (h: any) => h(AccessDeniedPage, { props: { authentication } })
    }).$mount('body>div');
};
